.ticket{
    display: flex;
    flex: 1;
   background: linear-gradient(#cbf762,#06a9a0);
    height: 100vh;
    justify-content: center;
    align-items:center;
}
.ticket h1{
    font-size: 80px;
    color: #08a7b2;
}