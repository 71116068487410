.soccer{
    display: flex;
    flex: 1;
    flex-direction: column;
   background: linear-gradient(#cbf762,#06a9a0);
    height: 100vh;
    justify-content: center;
    align-items:center;
}
.soccer h1{
    font-size: 45px;
    margin-bottom: 10px;
    color: #06a9a0;
}


.soccer .myselect{
    width: 300px;
    padding: 10px;
    -webkit-appearance: none;
   
}
.soccer #myselect{
    width: 40%;
    padding: 10px;
    -webkit-appearance: none;
    background-color: #06a9a0;
    border: 4px solid #deff5a;
    border-radius: 30px;
    outline: none;
    font-size: 18px;
    font-weight: bold;
    color: #deff5a;
}

.soccer .myarrow{
   
    background: url(./down-arrow-in-a-circle.png)no-repeat;
    background-size:contain;
    background-position: right center;
    background-color: #06a9a0!important;
    border: 4px solid #deff5a!important;
    color:#deff5a!important ;
}

.soccer .myclose{
   
    background: url(./black-circle-close-button-png-5.png)no-repeat;
    background-size:contain;
    background-position: right;
    background-color: #deff5a!important;
    border: 4px solid #06a9a0!important;
    color: #06a9a0!important;
}

