.nav .topnav{
    height: 11%;
    width: 100%;
    background-color: #06a9a0;
    position: fixed;
   top: -11%;
    left: 0;
    right: 0;
    text-align: center;
  direction: rtl;
  transition: 0.5s ease all;
}

.nav .topnav.animation{
    top: 0;
    transition: 0.5s ease all;
}

.nav .topnav ul.list > li{
   display: inline-block;
    list-style: none;
    margin: 20px  35px 0 35px;
   position: relative;
  
}
.nav .topnav ul.list > li:not(:last-of-type){
   padding: 7px 0;
    border-radius: 10px;
    padding: 3px 4px;
 }
.nav .topnav ul.list > li:not(:last-of-type):hover{
    background-color: #cbf762;
}
.nav .topnav ul.list > li:not(:last-of-type):hover a{
   
    color: #06a9a0;
}
.nav .topnav ul.list > li:not(:last-of-type):active a{
    background-color: #06a9a0;
 color: #cbf762;
 
}
.nav .topnav ul.list > li > a{
    text-decoration: none;
    color: white;
     padding: 3px 8px; 
    display: block;
   border-radius: 7px;
}
.nav .topnav ul.list > li:last-of-type a{
    padding-bottom: 23px;
}
.nav .topnav ul li ul{
    position: absolute;
    top: 45px;
    left: -30px;
    background-color: #cbf762;
   width: 150px;
   display: none;
}
.nav .topnav ul li ul li{
    list-style: none;
   padding: 10px;
   margin: 3px 0;
   background-color: #06a9a0;
}
.nav .topnav ul li ul li a{
    text-decoration: none;
    color: white;
}
.nav .topnav ul li ul li:hover{
    background-color: #cbf762;
   font-weight: 500;
   font-size: 17px;
}
.nav .topnav ul li ul li:hover a{
    color:#06a9a0 ;
}
.nav .topnav ul li:last-of-type:hover ul{
    display: block;
}


/* button */

.nav .topnav::before{
    content: '';
    width: 100%;
    height: 70px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color:#cbf762;
    background-image: url(./logo-2.png);
    height: 36px;
    background-repeat: no-repeat;
    background-position:left center;
    background-size: contain;
}
